import mitt from "mitt"
import gbModal from "./gbModal.vue"

import type { App } from "vue"

const emitter = mitt()

const show = (name: string, params?: any): void => {
	emitter.emit("toggle", [name, true, params])
}

const hide = (name: string): void => {
	emitter.emit("toggle", [name, false, {}])
}

const toggle = (name: string, params?: any): void => {
	emitter.emit("toggle", [name, undefined, params])
}

export class useModal {
	public static show = show
	public static hide = hide
	public static toggle = toggle
	public static emitter = emitter
}

export default {
	install(app: App) {
		app.component("gbModal", gbModal)
		app.config.globalProperties.emitter = emitter
		app.config.globalProperties.$gbModal = { show, hide, toggle, emitter }
	},
}
