import appHeader from "@website/components/header/appHeader.vue"

const Events__Register = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/register.vue"
	)
const Events__Register_SelectEvent = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/selectEvent.page.vue"
	)
const Events__Register_Quantity = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/quantity.page.vue"
	)
const Events__Register_Participants = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/participants.page.vue"
	)
const Events__Register_Payment = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/payment.page.vue"
	)
const Events__Register_Confirmation = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/registration-confirmation.page.vue"
	)
const Events__Register_Customer = () =>
	import(
		/* webpackChunkName: "app-route-events" */ "@website/components/events/customer.page.vue"
	)

const routes = [
	{
		path: "/classes-and-events/register",
		name: "Events__Register",
		components: {
			appHeader,
			default: Events__Register,
		},
		children: [
			{
				path: "/classes-and-events/register",
				name: "Events__Register_SelectEvent",
				components: {
					default: Events__Register_SelectEvent,
				},
			},
			{
				path: "/classes-and-events/register/quantity",
				name: "Events__Register_Quantity",
				components: {
					default: Events__Register_Quantity,
				},
			},
			{
				path: "/classes-and-events/register/participants",
				name: "Events__Register_Participants",
				components: {
					default: Events__Register_Participants,
				},
			},
			{
				path: "/classes-and-events/register/customer",
				name: "Events__Register_Customer",
				components: {
					default: Events__Register_Customer,
				},
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Events__Register_SelectEvent",
							"Events__Register_Participants",
							"Events__Register_Payment",
						].includes(from.name as string)
					) {
						next()
					} else next({ name: "Events__Register_SelectEvent" })
				},
			},
			{
				path: "/classes-and-events/register/payment",
				name: "Events__Register_Payment",
				components: {
					default: Events__Register_Payment,
				},
				beforeEnter: (to, from, next) => {
					if (
						typeof from.name === "string" &&
						from.name === "Events__Register_Customer"
					) {
						next()
					} else next({ name: "Events__Register_SelectEvent" })
				},
			},
			{
				path: "/classes-and-events/register/confirmation/:eventID/:customerID/:receiptNumber",
				name: "Events__Register_Confirmation",
				components: {
					default: Events__Register_Confirmation,
				},
				props: { default: true },
			},
		],
	},
]

export default routes
