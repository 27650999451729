import { getObjectByType, getObjectsByType } from "@shared/api/JRPHelper"
import api from "./api"

const shopCategoriesPath = "shop-categories/"

import type { IShopCategory } from "types/ShopCategory"

export {
	getChildShopCategoriesByID,
	getRecipeBuilderShopCategories,
	getShopCategories,
	getShopCategoryByID,
	getShopCategoryByURL,
}

/**
 * @returns {Array } array of Category objects
 */
const getShopCategories = async (): Promise<IShopCategory[]> => {
	try {
		const { data } = await api.get(shopCategoriesPath, {
			validateStatus: (status) => {
				return status === 200
			},
		})
		return getObjectsByType<IShopCategory>(data, "ShopCategory")
	} catch (error) {
		return Promise.reject(error)
	}
}

/**
 *
 * @param {string} categoryURL
 * @returns {object} ShopCategory object
 */
const getShopCategoryByURL = async (
	categoryURL: string,
): Promise<IShopCategory | null> => {
	try {
		const { data } = await api.get(
			shopCategoriesPath + "url/" + categoryURL,
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		return getObjectByType<IShopCategory>(data, "ShopCategory")
	} catch (error) {
		return Promise.reject(error)
	}
}

/**
 *
 * @param {number} categoryID
 * @returns {object} ShopCategory object
 */
const getShopCategoryByID = async (
	categoryID: number,
): Promise<IShopCategory | null> => {
	try {
		const { data } = await api.get(
			shopCategoriesPath + "id/" + categoryID,
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		return getObjectByType<IShopCategory>(data, "ShopCategory")
	} catch (error) {
		return Promise.reject(error)
	}
}

/**
 * @returns {Array} array of Category objects
 */
const getRecipeBuilderShopCategories = async (): Promise<IShopCategory[]> => {
	try {
		const { data } = await api.get(shopCategoriesPath + "recipe-builder", {
			validateStatus: (status) => {
				return status === 200
			},
		})
		return getObjectsByType<IShopCategory>(data, "ShopCategory")
	} catch (error) {
		return Promise.reject(error)
	}
}

const getChildShopCategoriesByID = async (
	parentCategoryID: number,
): Promise<IShopCategory[]> => {
	try {
		const { data } = await api.get(
			shopCategoriesPath + "id/" + parentCategoryID + "/children",
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		return getObjectsByType<IShopCategory>(data, "ShopCategory")
	} catch (error) {
		return Promise.reject(error)
	}
}
