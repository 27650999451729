export { fnConvertOunces }

/**
 * Utility to handle 'o' keypress conversion of ounces to pounds
 * Input is in format pounds.ounces
 * For example, 1.8 o => 1.5 pounds
 * Can accept ounces with decimal, such as 1.5.3 o => 1.328125 pounds
 * @param input string
 * @returns number
 */
const fnConvertOunces = (input: string): number => {
	let pounds: number = 0
	if (input.indexOf(".") > -1) {
		//pounds is portion of string before first decimal
		const decimalIndex = input.indexOf(".")
		const poundsString = input.substring(0, decimalIndex)
		const ouncesString = input.substring(decimalIndex + 1)
		pounds = parseInt(poundsString) + parseFloat(ouncesString) / 16
	} else {
		pounds = parseFloat(input) / 16
	}
	const roundedPounds = Math.round(pounds * 100) / 100
	return roundedPounds
}
