import axios from "axios"
import { ref } from "vue"
export { api, useGetLocalDeliverySettings }
const api = axios.create({
	baseURL: "/api/v0/public/",
})

const localdelivery = {
	fee: 7.99,
	zipcodes: [
		"60610",
		"60613",
		"60614",
		"60618",
		"60622",
		"60625",
		"60626",
		"60640",
		"60642",
		"60647",
		"60657",
		"60659",
		"60660",
	],
}
const useGetLocalDeliverySettings = () => {
	const fee = ref(localdelivery.fee)
	const zipcodes = ref(localdelivery.zipcodes)
	return { fee, zipcodes }
}
