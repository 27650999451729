import type {
	IErrorAuthenticationError,
	IErrorNetworkError,
	IErrorRequestError,
	IErrorRequestNotFoundError,
	IErrorServerError,
} from "Errors"
export class NetworkError extends Error implements IErrorNetworkError {
	public constructor() {
		super("NetworkError")
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, NetworkError)
		}
		this.name = "NetworkError"
		Object.setPrototypeOf(this, new.target.prototype)
	}
}

export class RequestError extends Error implements IErrorRequestError {
	public key: string
	public params: unknown[]
	public constructor(key: string, message?: string, ...params: unknown[]) {
		super(message)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, RequestError)
		}
		this.key = key
		this.name = "RequestError"
		this.params = params
		Object.setPrototypeOf(this, new.target.prototype)
	}
}

export class RequestNotFoundError
	extends RequestError
	implements IErrorRequestNotFoundError
{
	public constructor(key: string, message?: string, ...params: unknown[]) {
		super(key, message, ...params)
		this.name = "RequestNotFoundError"
	}
}

export class ServerError extends Error implements IErrorServerError {
	public constructor(message?: string) {
		super("ServerError")
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ServerError)
		}
		this.name = "ServerError"
		if (message) {
			this.message = message
		}
		Object.setPrototypeOf(this, new.target.prototype)
	}
	params: any[] = []
}

export class AuthenticationError
	extends Error
	implements IErrorAuthenticationError
{
	public constructor() {
		super("AuthenticationError")
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, AuthenticationError)
		}
		this.name = "AuthenticationError"
		Object.setPrototypeOf(this, new.target.prototype)
	}
}
