export { fnIsNumeric, fnIsPositiveNumeric }
/**
 * Returns true if the value is numeric
 * @param value any
 */
const fnIsNumeric = (value: any): boolean => {
	return !isNaN(parseFloat(value)) && isFinite(value)
}

/**
 * Returns true if the value is numeric and positive
 * @param value any
 */
const fnIsPositiveNumeric = (value: any): boolean => {
	return fnIsNumeric(value) && value >= 0
}
