<div id="recipe"><RecipeBuilder_QuickAdd></RecipeBuilder_QuickAdd><h3 class="center" v-if="recipe.items.length == 0">Add Items To Your Recipe</h3><div class="cart" v-else><div class="cart-item header"><div class="cart-item-cell imagecol"></div><div class="cart-item-cell namecol">Item</div><div class="cart-item-cell pricecol">Price</div><div class="cart-item-cell inputcol">Quantity</div><div class="cart-item-cell pricecol"> </div><div class="cart-item-cell buttoncol"></div></div><CartItem v-for="item in sortedCart" :key="item.sku" :item="item" :recipeID="recipe.id" :editmode="true"></CartItem><span><div class="cart-item footer"><div class="cart-item-cell imagecol"></div><div class="cart-item-cell namecol"></div><div class="cart-item-cell pricecol"></div><div class="cart-item-cell inputcol">Subtotal:</div><div class="cart-item-cell pricecol">${{subtotal.toFixed(2)}}</div><div class="cart-item-cell buttoncol"></div></div></span></div><form class="form-aligned" @submit.prevent><template v-if="needsMilling"><label>Mill Grains</label><input type="checkbox" v-model="recipe.millGrains"></template><label>Special Instructions</label><textarea class="noresize" v-model="recipe.specialInstructions"><!--ButtonBarButton_Large(
	v-if="(recipe.items.length > 0) && newRecipe"
	@click="save"
) Save
Button_Large(
	v-if="newRecipe"
	@click="cancel"
) Cancel
Button_Large(
	v-else
	@click="close"
) Save--></textarea></form></div>