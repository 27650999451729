import Fuse from "fuse.js"
import { computed, ref, watch } from "vue"

import type { FuseIndex, FuseResult, IFuseOptions } from "fuse.js"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { ComputedRef, Ref } from "vue"

export interface ISearchItem {
	score: number
	searchTerm: string
	item: IPublicInventoryItem
}
export { useInventoryItemsSearch }

const itemOptions: IFuseOptions<IPublicInventoryItem> = {
	keys: ["name", "search_keywords", "description_short", "description_long"],
	threshold: 0.3,
	ignoreLocation: true,
	includeScore: true,
}
const resultsLimit = 20

const useInventoryItemsSearch = (
	items: Ref<IPublicInventoryItem[]> | Ref<undefined>,
	index: Ref<FuseIndex<IPublicInventoryItem>> | Ref<undefined>,
	searchTerm: Ref<string>,
): { results: ComputedRef<ISearchItem[]>; ready: ComputedRef<boolean> } => {
	const itemFuse = ref<Fuse<IPublicInventoryItem> | undefined>(undefined)
	const itemResults = ref<FuseResult<IPublicInventoryItem>[] | undefined>(
		undefined,
	)

	const updateFuse = (
		items: Ref<IPublicInventoryItem[]>,
		index: Ref<FuseIndex<IPublicInventoryItem>>,
	): void => {
		if (items.value === undefined || itemOptions.keys === undefined) {
			return
		}
		const itemsIndex = Fuse.parseIndex(index.value)
		itemFuse.value = new Fuse(items.value, itemOptions, itemsIndex)
	}

	const search = (searchTerm: Ref<string>) => {
		if (itemFuse.value === undefined)
			throw new Error("Fuse is not initialized")
		itemResults.value = itemFuse.value.search(searchTerm.value)
	}

	watch(
		[items, index],
		() => {
			if (items.value !== undefined && index.value !== undefined) {
				updateFuse(items, index)
			}
		},
		{ immediate: true },
	)
	watch(searchTerm, () => {
		if (items.value !== undefined && index.value !== undefined) {
			updateFuse(items, index)
		}
		search(searchTerm)
	})

	const results = computed((): ISearchItem[] => {
		let searchItems = [] as ISearchItem[]
		if (itemResults.value !== undefined) {
			searchItems = itemResults.value.map((item) => {
				return {
					score: item.score,
					type: "item",
					searchTerm: searchTerm.value,
					item: item.item,
				}
			}) as ISearchItem[]
			searchItems.sort((a, b) => a.score - b.score)
		}
		return searchItems.slice(0, resultsLimit)
	})

	const ready = computed((): boolean => itemFuse.value !== undefined)

	return { results, ready }
}
