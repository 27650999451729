import type { IJRP, IJRPError } from "types/JRP"
interface ITestJRP {
	errors?: IJRPError[]
}

export {
	getErrorByType,
	getErrorsByType,
	getObjectByType,
	getObjectsByType,
	hasJRPErrors,
}

//TODO: Should return null if no object is found
const getObjectByType = <Type>(JRP: IJRP, type: string): Type | null => {
	//if (JRP.version !== 0.1) throw Error("Invalid JRP version")
	if (JRP.version !== 0.1) {
		console.warn("Invalid JRP version value: " + JRP.version)
	}
	if (JRP.data) {
		const object = JRP.data.find((object) => object.type === type)
		return (object?.object as Type) ?? null
	} else {
		throw Error("JRP.data is undefined")
	}
}

const getObjectsByType = <Type>(JRP: IJRP, type: string): Type[] => {
	//if (JRP.version !== 0.1) throw Error("Invalid JRP version")
	if (JRP.version !== 0.1) {
		console.warn("Invalid JRP version value: " + JRP.version)
	}
	if (JRP.data) {
		const result = JRP.data.filter((object) => object.type === type)
		const objects = result.map((entry) => entry.object)
		return objects as Type[]
	} else {
		throw Error("JRP.data is undefined")
	}
}

const getErrorByType = <Type>(JRP: IJRP, type: string): Type => {
	//if (JRP.version !== 0.1) throw Error("Invalid JRP version")
	if (JRP.version !== 0.1) {
		console.warn("Invalid JRP version value: " + JRP.version)
	}
	if (JRP.errors) {
		const error = JRP.errors.find((error) => error.type === type)
		if (!error) throw Error("No error found with type: " + type)
		return error.error as Type
	} else {
		throw Error("JRP.errors is undefined")
	}
}

const getErrorsByType = <Type>(JRP: IJRP, type: string): Type[] => {
	//if (JRP.version !== 0.1) throw Error("Invalid JRP version")
	if (JRP.version !== 0.1) {
		console.warn("Invalid JRP version value: " + JRP.version)
	}
	if (JRP.errors) {
		const result = JRP.errors.filter((error) => error.type === type)
		const errors = result.map((entry) => entry.error)
		return errors as Type[]
	} else {
		throw Error("JRP.errors is undefined")
	}
}

const hasJRPErrors = (JRP: unknown): boolean => {
	if (typeof JRP === "object" && JRP !== null) {
		const TJRP = JRP as ITestJRP
		if (Object.hasOwn(TJRP, "errors") && Array.isArray(TJRP.errors)) {
			return TJRP.errors.every(
				(error) =>
					typeof error === "object" &&
					error !== null &&
					Object.hasOwn(error, "type") &&
					Object.hasOwn(error, "key") &&
					Object.hasOwn(error, "object"),
			)
		}
	}
	return false
}

/** @deprecated don't use default export, import individual methods */
const JRPHelper = {
	getErrorsByType,
	getObjectByType,
	getObjectsByType,
	hasJRPErrors,
}

export default JRPHelper
