import { defineStore } from "pinia"
import { ref } from "vue"

import type {
	ICustomer,
	ICustomerPreferences,
	ICustomerProfile,
	IDeliveryAddress,
	IShippingAddress,
} from "types/Customer"
import type { IRecipe } from "types/Recipe"

export interface RecipeActivity {
	times_ordered: number
	last_purchase_date: string
	recipe: IRecipe
}

export type RecipeHistory = RecipeActivity

export const useAccountStore = defineStore("account", () => {
	const isLoggedIn = ref<boolean>(false)
	const customerToken = ref<ICustomer["token"]>()
	const profile = ref<ICustomerProfile>()
	const preferences = ref<ICustomerPreferences>()
	const shippingAddresses = ref<IShippingAddress[]>([])
	const deliveryAddresses = ref<IDeliveryAddress[]>([])

	const loginWithCustomer = (customer: ICustomer): void => {
		isLoggedIn.value = true
		customerToken.value = customer.token
		profile.value = customer.profile
		preferences.value = customer.preferences
	}

	const resetStore = (): void => {
		isLoggedIn.value = false
		customerToken.value = undefined
		profile.value = undefined
		preferences.value = undefined
		shippingAddresses.value = []
		deliveryAddresses.value = []
	}

	const logout = (): void => {
		resetStore()
	}

	return {
		isLoggedIn,
		customerToken,
		profile,
		preferences,
		shippingAddresses,
		deliveryAddresses,
		loginWithCustomer,
		logout,
		resetStore,
	}
})
