import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "RecipeBuilder-Categories" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.recipeBuilderCategories, (category) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["RecipeBuilder-Category", {'selected' : $setup.filterCategory === category}]),
        onClick: _withModifiers(($event: any) => ($setup.setFilterCategory(category)), ["stop"])
      }, _toDisplayString(category.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}