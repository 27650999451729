import appHeader from "@website/components/header/appHeader.vue"

const Checkout = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/checkout.vue"
	)
const Checkout_Confirm = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/confirm.page.vue"
	)
const Checkout_Customer = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/customer.page.vue"
	)
const Checkout_DeliveryMethod = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/deliveryMethod.page.vue"
	)
const Checkout_Delivery = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/delivery.page.vue"
	)
const Checkout_Shipping = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/shipping.page.vue"
	)
const Checkout_ConfirmDeliveryOptions = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/confirmDeliveryOptions/confirmDeliveryOptions.page.vue"
	)
const Checkout_Payment = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/payment.page.vue"
	)
const Checkout_OrderConfirmation = () =>
	import(
		/* webpackChunkName: "app-route-checkout" */ /* webpackPrefetch: true */ "@website/components/checkout/order-confirmation.page.vue"
	)

const routes = [
	{
		path: "/checkout",
		name: "Checkout",
		components: {
			appHeader,
			default: Checkout,
			//appFooter,
		},

		children: [
			{
				path: "/checkout",
				name: "Checkout_Confirm",
				components: { default: Checkout_Confirm },
			},
			{
				path: "/checkout/customer",
				name: "Checkout_Customer",
				components: { default: Checkout_Customer },
				beforeEnter: (to, from, next) => {
					if (
						!from.name ||
						![
							"Checkout_Confirm",
							"Checkout_DeliveryMethod",
						].includes(from.name.toString())
					)
						next("/shop")
					else next()
				},
			},
			{
				path: "/checkout/delivery-method",
				name: "Checkout_DeliveryMethod",
				components: { default: Checkout_DeliveryMethod },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Checkout_Customer",
							"Checkout_ConfirmCustomerPreferences",
							"Checkout_Delivery",
							"Checkout_Shipping",
							"Checkout_Payment",
						].includes(from.name.toString())
					)
						next()
					else next("/shop")
				},
			},
			{
				path: "/checkout/delivery",
				name: "Checkout_Delivery",
				components: { default: Checkout_Delivery },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Checkout_DeliveryMethod",
							"Checkout_Payment",
						].includes(from.name.toString())
					)
						next()
					else next("/shop")
				},
			},
			{
				path: "/checkout/shipping",
				name: "Checkout_Shipping",
				components: { default: Checkout_Shipping },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Checkout_DeliveryMethod",
							"Checkout_Payment",
						].includes(from.name.toString())
					)
						next()
					else next("/shop")
				},
			},
			{
				path: "/checkout/confirm-preferences",
				name: "Checkout_ConfirmDeliveryOptions",
				components: { default: Checkout_ConfirmDeliveryOptions },
			},
			{
				path: "/checkout/payment",
				name: "Checkout_Payment",
				components: { default: Checkout_Payment },
				beforeEnter: (to, from, next) => {
					if (
						typeof from.name === "string" &&
						[
							"Checkout_DeliveryMethod",
							"Checkout_Shipping",
							"Checkout_Delivery",
							"Checkout_ConfirmDeliveryOptions",
						].includes(from.name)
					)
						next()
					else {
						next("/shop")
					}
				},
			},
			{
				path: "/checkout/order-confirmation/:ordernumber/:receiptnumber/:customerid",
				name: "Checkout_OrderConfirmation",
				components: { default: Checkout_OrderConfirmation },
			},
		],
	},
]

export default routes
