// @ts-check
const OrderStatus = {
	SAVED: "saved",
	ORDERED: "ordered",
	INVOICED: "invoiced",
	PAYED: "payed",
	RECEIVED: "received",
	FULFILLED: "fulfilled",
	PICKEDUP: "pickedup",
	OUTFORDELIVERY: "out_for_delivery",
	DELIVERED: "delivered",
	SHIPPED: "shipped",
	SHIPPED_DELIVERED: "shipped_delivered",
	CANCELED: "canceled",
	EXCEPTION: "exception",
}
module.exports = OrderStatus
