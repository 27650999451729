const Shop_Shop = () =>
	import(
		/* wepackChunkName: "app-route-shop" */ /* webpackPrefetch: true */ "@website/components/shop/shop.vue"
	)
const Shop_PrimaryCategories = () =>
	import(
		/* wepackChunkName: "app-route-shop" */ /* webpackPrefetch: true */ "@website/components/shop/primary-categories.page.vue"
	)
const Shop_CategoryView = () =>
	import(
		/* wepackChunkName: "app-route-shop" */ /* webpackPrefetch: true */ "@website/components/shop/category-view.page.vue"
	)
const Shop_ItemView = () =>
	import(
		/* wepackChunkName: "app-route-shop" */ /* webpackPrefetch: true */ "@website/components/shop/ItemView/itemView.page.vue"
	)

import appFooter from "@website/components/appFooter.vue"
import appHeader from "@website/components/header/appHeader.vue"

const routes = [
	{
		path: "/shop",
		name: "Shop",
		components: {
			appHeader,
			default: Shop_Shop,
			appFooter,
		},
		children: [
			{
				path: "/shop",
				name: "Primary Categories",
				components: { default: Shop_PrimaryCategories },
			},
			{
				path: "/shop/items/:sku",
				name: "Item View By SKU",
				components: { default: Shop_ItemView },
				props: { default: true },
			},
			{
				path: "/shop/items/:name/:sku",
				name: "Item View By SKU",
				components: { default: Shop_ItemView },
				props: { default: true },
			},
			{
				path: "/shop/sku/:sku",
				redirect: "/shop/items/:sku",
			},
			{
				path: "/shop/:categoryURL/items/:name/:sku",
				name: "Item View",
				components: { default: Shop_ItemView },
				props: { default: true },
			},
			{
				path: "/shop/:thisCategoryURL",
				name: "Primary Category View",
				components: { default: Shop_CategoryView },
				props: { default: true },
			},
			{
				path: "/shop/:parentCategoryURL/:categoryURL/items/:name/:sku",
				name: "Item View2",
				components: { default: Shop_ItemView },
				props: { default: true },
			},
			{
				path: "/shop/:parentCategoryURL/:thisCategoryURL",
				name: "Secondary Category View",
				components: { default: Shop_CategoryView },
				props: { default: true },
			},
			{
				path: "/shop/:grandParentCategoryURL/:parentCategoryURL/:categoryURL/items/:name/:sku",
				name: "Item View3",
				components: { default: Shop_ItemView },
				props: { default: true },
			},
			{
				path: "/shop/:grandParentCategoryURL/:parentCategoryURL/:thisCategoryURL",
				name: "Tertiary Category View",
				components: { default: Shop_CategoryView },
				props: { default: true },
			},
		],
	},
]

export default routes
