import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "RecipeBuilder" }
const _hoisted_2 = { id: "RecipeBuilder-ItemsContainer" }
const _hoisted_3 = {
  id: "RecipeBuilder-Items",
  tabindex: "-1"
}
const _hoisted_4 = { id: "RecipeBuilder-Search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["RecipeBuilder_Categories"], {
        modelValue: $setup.filterCategory,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.filterCategory) = $event))
      }, null, 8 /* PROPS */, ["modelValue"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredInventory, (item, index) => {
            return (_openBlock(), _createBlock($setup["RecipeBuilder_Item"], {
              key: item.sku,
              resultRank: index,
              item: item,
              recipe: $setup.recipe,
              onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8 /* PROPS */, ["resultRank", "item", "recipe"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["RecipeBuilder_SearchBox"], {
            isSearchLoading: $setup.isSearchLoading,
            modelValue: $setup.searchTerm,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchTerm) = $event))
          }, null, 8 /* PROPS */, ["isSearchLoading", "modelValue"])
        ])
      ])
    ]),
    _createVNode($setup["RecipeName"], {
      isNewRecipe: $setup.isNewRecipe,
      recipeName: $setup.recipe.name,
      onSaveRecipeName: $setup.handleSaveNewName,
      onCancelNewRecipe: $setup.handleCancelNewRecipe
    }, null, 8 /* PROPS */, ["isNewRecipe", "recipeName"])
  ], 64 /* STABLE_FRAGMENT */))
}