import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showOverlay)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "gb-modal-overlay",
        ref: "gbModalOverlay",
        onMousedown: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOverlayClick && _ctx.handleOverlayClick(...args)), ["self"])),
        onTouchstart: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOverlayClick && _ctx.handleOverlayClick(...args)), ["self"]))
      }, [
        (_ctx.showModal)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "gb-modal-body",
              ref: "gbModalBody",
              style: _normalizeStyle(_ctx.modalStyle)
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 4 /* STYLE */))
          : _createCommentVNode("v-if", true)
      ], 544 /* NEED_HYDRATION, NEED_PATCH */))
    : _createCommentVNode("v-if", true)
}