const isNavigationKey = (key: KeyboardEvent["key"]): boolean => {
	const isNavigationKey = [
		"Tab",
		"ArrowLeft",
		"ArrowRight",
		"ArrowUp",
		"ArrowDown",
		"Home",
		"End",
		"PageUp",
		"PageDown",
	].includes(key)
	return isNavigationKey
}

const isEditingKey = (key: KeyboardEvent["key"]): boolean => {
	const isEditingKey = [
		"Backspace",
		"Delete",
		"Clear",
		"Copy",
		"CrSelect",
		"EraseEof",
		"ExSel",
		"Insert",
		"Paste",
		"Redo",
		"Undo",
	].includes(key)
	return isEditingKey
}

const isValidDecimalKey = (key: KeyboardEvent["key"]): boolean => {
	const isNumber = "0" <= key && key <= "9"
	const isDot = key === "."

	if (isNumber || isDot) return true
	else return false
}

export const preventInvalidDecimalKey = (event: KeyboardEvent): void => {
	if (isNavigationKey(event.key)) return
	if (isEditingKey(event.key)) return
	if (!isValidDecimalKey(event.key)) event.preventDefault()
}
