import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow } from "vue"

const _hoisted_1 = { id: "search" }
const _hoisted_2 = { id: "searchwrapper" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { id: "searchlist" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: "searchbox",
        class: _normalizeClass({ 'searchbox-loading': $setup.isSearchLoading}),
        disabled: $setup.isSearchLoading,
        type: "search",
        inputmode: "search",
        autocorrect: "off",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchTerm) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.debouncedSearch && $setup.debouncedSearch(...args))),
        onKeyup: _withKeys($setup.resetSearch, ["esc"])
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3), [
        [_vModelText, $setup.searchTerm]
      ]),
      _createCommentVNode("#clearicon(@click=\"resetSearch\"\n)\nimg(src=\"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/icons/clear-white-48dp.svg\")"),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredResults, (searchItem, index) => {
          return (_openBlock(), _createBlock($setup["SearchItem"], {
            key: $setup.getKey(searchItem),
            searchItem: searchItem,
            resultRank: index,
            onGoToItem: $setup.goToItem
          }, null, 8 /* PROPS */, ["searchItem", "resultRank"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.showSearchBox]
    ]),
    _createElementVNode("img", {
      id: "searchicon",
      src: "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/icons/search-white-48dp.svg",
      onClick: $setup.toggleSearchBar
    })
  ]))
}