import api from "./api"

export const getStaticPageContent = async (
	pageName: string,
): Promise<{ status: number; html: string }> => {
	try {
		const url = window.location.origin + "/html/" + pageName + ".html"
		const { status, data: html } = await api.get(url, {
			validateStatus: (status) => {
				return status === 200 || status === 404
			},
		})
		return { status, html }
	} catch (error) {
		return Promise.reject(error)
	}
}
