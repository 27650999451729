import appFooter from "@website/components/appFooter.vue"
import appHeader from "@website/components/header/appHeader.vue"

const Newsletters = () =>
	import(
		/* webpackChunkName: "app-route-newsletters" */ "@website/components/newsletter/newsletters.page.vue"
	)
const Newsletter = () =>
	import(
		/* webpackChunkName: "app-route-newsletters" */ "@website/components/newsletter/newsletter.page.vue"
	)

const routes = [
	{
		path: "/newsletter/:url",
		name: "Newsletter",
		components: {
			appHeader,
			default: Newsletter,
			appFooter,
		},
		props: {
			default: true,
		},
	},
	{
		path: "/newsletter",
		name: "Newsletters",
		components: {
			appHeader,
			default: Newsletters,
			appFooter,
		},
	},
]

export default routes
