//LOGIN ROUTES
const Customer_Login = () =>
	import(
		/* webpackChunkName: "app-router-customer-login" */ "../components/account/login/customer-login.vue"
	)
const Customer_Register = () =>
	import(
		/* webpackChunkName: "app-router-customer-login" */ "../components/account/login/customer-register.vue"
	)
const Customer_ResetPassword = () =>
	import(
		/* webpackChunkName: "app-router-customer-login" */ "../components/account/resetPassword/resetPassword.vue"
	)
const Customer_ResetPasswordEmail = () =>
	import(
		/* webpackChunkName: "app-router-customer-login" */ "../components/account/resetPassword/resetPasswordEmail.vue"
	)

//ACCOUNT ROUTES
const Account_Settings = () =>
	import(
		/* webpackChunkName: "app-router-customer-account" */ "@website/components/account/settings/account-settings.page.vue"
	)
const Account = () =>
	import(
		/* webpackChunkName: "app-router-customer-account" */ "@website/components/account/account.vue"
	)
const CustomerOrders = () =>
	import(
		/* webpackChunkName: "app-router-customer-account" */ "@website/components/account/orders/customer-orders.page.vue"
	)
const CustomerRecipes = () =>
	import(
		/* webpackChunkName: "app-router-customer-account" */ "@website/components/account/recipes/customer-recipes.vue"
	)

import appHeader from "@website/components/header/appHeader.vue"
import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
	{
		path: "/login",
		name: "Customer_Login",
		components: {
			appHeader,
			default: Customer_Login,
		},
	},
	{
		path: "/reset-password-email",
		name: "Customer_ResetPasswordEmail",
		components: {
			appHeader,
			default: Customer_ResetPasswordEmail,
		},
	},
	{
		path: "/reset-password",
		name: "Customer_ResetPassword",
		components: {
			appHeader,
			default: Customer_ResetPassword,
		},
	},
	{
		path: "/register",
		name: "Customer_Register",
		components: { appHeader, default: Customer_Register },
	},
	{
		path: "/account",
		name: "Account",
		components: {
			appHeader,
			default: Account,
		},
		children: [
			{
				path: "",
				name: "Account_Home",
				redirect: "/account/settings",
			},
			{
				path: "orders",
				name: "Account_Orders",
				components: { default: CustomerOrders },
			},
			{
				path: "recipes",
				name: "Account_Recipes",
				components: { default: CustomerRecipes },
			},
			{
				path: "settings",
				name: "Account_Settings",
				components: { default: Account_Settings },
			},
		],
	},
]

export default routes
